body,
a,
p,
ul,
li {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

li {
  list-style-type: none;
}

body {
  background-color: #d6d6d6 !important;
  overflow-x: hidden;
  position: relative;
}

.navbar {
  background-color: #26282E;
}

#header {
  width: 100%;
  height: 80vh;
  overflow: hidden;
  position: relative;
}
#header .header-image {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/header-new.png");
  background-size: auto 1700px;
  background-position: center 120vh;
  background-attachment: fixed;
}
#header .header-logo {
  height: 100px;
  width: 100%;
  background-image: url("../../assets/images/header-text-small.svg");
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 50%;
  margin-top: -50px;
}
#header .header-item {
  background-image: url(../../assets/images/header-item.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  position: absolute;
  width: 300px;
  height: 300px;
  top: 85%;
  left: 60%;
  background-size: 300px 300px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 10px;
}

#skills {
  background-color: #d6d6d6 !important;
  padding-top: 20px;
  padding-bottom: 100px;
  height: 80vh;
}
#skills h1 {
  margin-bottom: 10px;
  font-size: 2em;
  text-align: center;
}
#skills .skill {
  grid-column: span 4;
  margin: 20px 0;
  text-align: center;
}
#skills .skill .skill-icon {
  font-size: 3em;
}
#skills .skill .skill-text {
  font-size: 1em;
}
#skills .skill .skill-img {
  margin-top: 1vw;
  width: 2.9em;
}

.container-login {
  position: relative;
  width: 250px;
  top: 30vh;
  text-align: center;
  margin: 0 auto;
}
#about-me {
  background-color: #c5c5c5 !important;
  padding-bottom: 100px;
  padding-top: 20px;
  height: 80vh;
}
#about-me h1 {
  margin-bottom: 10px;
  font-size: 2em;
  text-align: center;
}
#about-me div {
  margin: 0 20px;
  font-size: 1.1em;
  padding: 20px 0;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
}
#about-me div h2 {
  margin-top: 20px;
}
#about-me div h4 {
  margin-top: 20px;
  font-size: 0.9em;
}
#about-me div p {
  font-size: 0.9em;
  margin: 0;
  margin-bottom: 10px;
  border: none;
}

#projects {
  padding-top: 20px;
  padding-bottom: 100px;
  height: 100%;
  min-height: 500px;
}
#projects h1 {
  margin-bottom: 10px;
  font-size: 2em;
  text-align: center;
}
#projects .project {
  margin: 20px;
  text-align: center;
  overflow: hidden;
  -ms-box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  -o-box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}
#projects .project .project-text {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.637);
  z-index: -1;
}
#projects .project .project-text div {
  position: relative;
  top: 45%;
}
#projects .project .project-text div .button {
  text-decoration: none;
  color: white;
  border: 1px solid white;
  padding: 10px 20px;
  margin-right: 10px;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
}
#projects .project .project-text div .button:hover {
  background-color: white;
  color: black;
}
#projects .project .project-img {
  transition: all 0.5s ease-in-out;
}
#projects .project {
  grid-column: span 12;
}
#projects .project:hover .project-img {
  transform: scale(1.1);
}
#projects .project:hover .project-text {
  z-index: 0;
}

footer {
  padding: 0 20px;
  background-color: #26282E;
  color: #e5e5e5;
}
footer .contact {
  padding: 0;
  grid-column: 1/7;
  margin-top: 10px;
}
footer .contact h5 {
  font-size: 1em;
}
footer p {
  text-align: center;
  grid-column: 2/12;
  grid-row: 2;
  font-size: 0.7em;
}
footer .social {
  grid-column: 9/12;
  grid-row: 1;
  display: flex;
  position: relative;
  right: 40px;
}
footer .social li a {
  width: 30px;
  height: 30px;
  background-color: #fff;
  text-align: center;
  line-height: 55px;
  font-size: 1em;
  margin: 30px 0 0 5px;
  display: block;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  border: 3px solid #fff;
  z-index: 1;
}
footer .social li a .i {
  position: relative;
  top: -64%;
  color: #262626;
  transition: 0.5s;
  z-index: 3;
}
footer .social li a:hover .i {
  color: #fff;
  transform: rotateY(360deg);
}
footer .social li a:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #202020;
  transition: 0.5s;
  z-index: 2;
}
footer .social li a:hover:before {
  top: 0;
}
footer .social li:nth-child(1) a:before {
  background: #3b5999;
}
footer .social li:nth-child(2) a:before {
  background: #55acee;
}
footer .social li:nth-child(3) a:before {
  background: #0077b5;
}
footer .social li:nth-child(4) a:before {
  background: #202020;
}

@media screen and (min-width: 280px) and (min-height: 653px) {
  #about-me div {
    font-size: 1em;
  }
}
@media screen and (min-height: 450px) {
  #skills h1 {
    margin-bottom: 5px;
    font-size: 2.3em;
    text-align: center;
  }
  #skills .skill {
    grid-column: span 4;
    margin: 10px 0;
    text-align: center;
  }
  #skills .skill .skill-icon {
    font-size: 3em;
  }
  #skills .skill .skill-text {
    font-size: 1em;
  }
  #skills .skill .skill-img {
    margin-top: 1vw;
    width: 2.9em;
  }

  #about-me h1 {
    margin-bottom: 5px;
    font-size: 2.3em;
    text-align: center;
  }
  #about-me div {
    margin: 0 20px;
    font-size: 1em;
  }
  #about-me div h2 {
    margin-top: 5px;
    font-size: 1.5em;
  }
  #about-me div h4 {
    margin-top: 5px;
    font-size: 1em;
  }
  #about-me div p {
    font-size: 1em;
    margin: 0;
  }
}
@media screen and (min-height: 560px) {
  #about-me h1 {
    font-size: 2.3em;
    text-align: center;
  }
  #about-me div {
    margin: 0 20px;
    font-size: 1em;
    padding: 10px 0;
  }
  #about-me div h4 {
    font-size: 1em;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  #about-me div h2 {
    font-size: 1.5em;
  }
  #about-me div p {
    font-size: 1em;
    margin-top: -15px;
    padding-bottom: 0;
  }
}
@media screen and (min-height: 650px) {
  #skills h1 {
    margin-bottom: 20px;
    font-size: 2.5em;
    text-align: center;
  }
  #skills .skill {
    grid-column: span 4;
    margin: 20px 0;
    text-align: center;
  }
  #skills .skill .skill-icon {
    font-size: 3em;
  }
  #skills .skill .skill-text {
    font-size: 1em;
  }
  #skills .skill .skill-img {
    margin-top: 1vw;
    width: 2.9em;
  }

  #about-me h1 {
    margin-bottom: 10px;
    font-size: 2.5em;
    text-align: center;
  }
  #about-me div {
    margin: 10px 20px;
    font-size: 1em;
  }
  #about-me div h2 {
    margin-top: 10px;
  }
  #about-me div h4 {
    margin-top: 10px;
    font-size: 1em;
  }
  #about-me div p {
    font-size: 1em;
    margin: 0;
    margin-top: 10px;
  }
}
@media screen and (min-height: 750px) {
  #skills h1 {
    font-size: 2.5em;
    text-align: center;
  }
  #skills .skill {
    grid-column: span 4;
    margin: 40px 0;
    text-align: center;
  }
  #skills .skill .skill-icon {
    font-size: 3em;
  }
  #skills .skill .skill-text {
    font-size: 1em;
  }
  #skills .skill .skill-img {
    margin-top: 1vw;
    width: 2.9em;
  }

  #about-me h1 {
    margin-bottom: 20px;
    font-size: 2.5em;
    text-align: center;
  }
  #about-me div {
    margin: 25px 20px;
    font-size: 1.2em;
  }
  #about-me div h2 {
    margin-top: 30px;
    margin-bottom: 10px;
  }
  #about-me div h4 {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 1em;
  }
  #about-me div p {
    font-size: 1em;
    margin: 0;
    margin-top: 10px;
  }

  #projects h1 {
    margin-bottom: 50px;
    font-size: 2.5em;
    text-align: center;
  }
}
@media screen and (min-width: 620px) {
  #skills {
    margin: 0 50px;
  }
  #skills h1 {
    margin-top: 50px;
    font-size: 2.5em;
    text-align: center;
  }
  #skills .skill {
    grid-column: span 3;
    margin: 20px 0;
    text-align: center;
  }
  #skills .skill .skill-icon {
    font-size: 3em;
  }
  #skills .skill .skill-text {
    font-size: 1em;
  }
  #skills .skill .skill-img {
    margin-top: 0.3vw;
    width: 2.9em;
  }

  footer .social {
    grid-column: 9/12;
    grid-row: 1;
    display: flex;
    position: relative;
    right: 0;
  }
  footer .social li a {
    width: 40px;
    height: 40px;
    background-color: #fff;
    text-align: center;
    line-height: 55px;
    font-size: 1.5em;
    margin: 30px 0 0 5px;
    display: block;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    border: 3px solid #fff;
    z-index: 1;
  }
  footer .social li a .i {
    position: relative;
    top: -30%;
    color: #262626;
    transition: 0.5s;
    z-index: 3;
  }
  footer .contact {
    padding: 0;
    grid-column: 2/9;
    margin-top: 10px;
  }
  footer .contact h5 {
    font-size: 1em;
  }

  #about-me {
    font-size: 1em;
  }
  #about-me h1 {
    margin-top: 20px;
    font-size: 2.5em;
    text-align: center;
  }
  #about-me div {
    margin: 40px 20px;
    font-size: 1em;
  }
  #about-me div h2 {
    margin-top: 40px;
  }
  #about-me div h4 {
    margin-top: 20px;
  }
  #about-me div p {
    margin: 0;
    margin-bottom: 20px;
  }

  #projects h1 {
    margin-top: 50px;
    font-size: 2.5em;
    text-align: center;
  }
}
@media screen and (min-width: 960px) {
  #skills {
    margin: 0 120px;
  }
  #skills .skill {
    grid-column: span 3;
    margin: 30px 0;
    text-align: center;
  }
  #skills .skill .skill-icon {
    font-size: 5em;
  }
  #skills .skill .skill-text {
    font-size: 1.5em;
  }
  #skills .skill .skill-img {
    margin-top: 0.6vw;
    width: 4.6em;
  }

  footer .social {
    grid-column: 9/12;
    grid-row: 1;
    display: flex;
    position: relative;
    right: 0;
  }
  footer .social li a {
    width: 50px;
    height: 50px;
    background-color: #fff;
    text-align: center;
    line-height: 55px;
    font-size: 1.8em;
    margin: 30px 0 0 5px;
    display: block;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    border: 3px solid #fff;
    z-index: 1;
  }
  footer .social li a .i {
    position: relative;
    top: -15%;
    color: #262626;
    transition: 0.5s;
    z-index: 3;
  }
  footer .contact {
    padding: 0;
    grid-column: 3/9;
    margin-top: 10px;
  }
  footer .contact h5 {
    font-size: 1.5em;
  }

  #about-me div {
    margin: 0 180px;
    font-size: 1em;
  }

  #projects .project {
    grid-column: span 6;
  }
}
@media screen and (min-width: 1200px) {
  #skills {
    margin: 0 180px;
  }
  #skills .skill {
    margin: 30px 0;
  }
  #skills .skill .skill-img {
    margin-top: 0.5vw;
    width: 4.7em;
  }

  #about-me div {
    margin: 0 250px;
  }

  footer .social {
    grid-column: 9/12;
    grid-row: 1;
    display: flex;
    position: relative;
    right: 0;
  }
  footer .social li a {
    width: 60px;
    height: 60px;
    background-color: #fff;
    text-align: center;
    line-height: 55px;
    font-size: 1.8em;
    margin: 30px 0 0 5px;
    display: block;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    border: 3px solid #fff;
    z-index: 1;
  }
  footer .social li a .i {
    position: relative;
    top: 0;
    color: #262626;
    transition: 0.5s;
    z-index: 3;
  }
  footer .contact {
    padding: 0;
    grid-column: 3/9;
    margin-top: 10px;
  }
  footer .contact h5 {
    font-size: 1.5em;
  }
}
@media screen and (min-width: 1500px) {
  #skills {
    margin: 0 400px;
  }
  #skills .skill .skill-img {
    margin-top: 0.4vw;
    width: 4.7em;
  }

  #about-me div {
    font-size: 1.2em;
    margin: 0 450px;
  }

  #projects .project {
    margin: 20px;
    text-align: center;
    overflow: hidden;
  }
}
@media screen and (min-width: 765px) and (min-height: 1023px) {
  #skills .skill {
    margin: 70px 0;
  }
  #skills .skill .skill-icon {
    font-size: 5em;
  }
  #skills .skill .skill-text {
    font-size: 1.5em;
  }
  #skills .skill .skill-img {
    margin-top: 0.6vw;
    width: 4.6em;
  }
}
@media screen and (min-width: 1024px) and (min-height: 1366px) {
  #about-me div {
    font-size: 2em;
  }

  #projects h1 {
    margin-top: 50px;
    font-size: 2.5em;
    text-align: center;
  }
}